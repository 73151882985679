<template>
	<div class="contact-container">
		<!-- <div style="position: relative;" class="contant-header" :style="{ '--backGroundImg': backGroundImgs}">
			<div class="text-title">联系我们</div>
		</div> -->
		<div style="position: relative;" class="contant-header">
			<img v-if="swipeList" :src="swipeList" class="bg-img" />
			<div v-else class="default-img"></div>
			<div class="text-title">{{$t('contact.callme')}}</div>
		</div>
		<div class="footer">
			<div class="flex-center">
				<div class="p-logo">
					<div class="logo">
						<img :src="deLogoSquare" />
					</div>
				</div>
				<div class="footer-contant">
					<img class="contant-img" :src="$store.state.headUrl" alt="" />
					<span class="contant-text"
						style="color: #656565;font-size: 15px !important;">{{ shopInfo.shopName }}</span>
					<div class="address contant-text">
						<div>
							<div style="display: flex;padding-top: 10px;" v-if="totalShopAddr">
								<div class="left-line"></div>
								<div class="title" v-html="$t('contact.address')"></div>
								<div class="title-content" style="width: 72%;">{{ totalShopAddr }}</div>
							</div>
							<div style="display: flex; padding-top: 10px;" v-if="shopInfo.shopPhone">
								<div class="left-line"></div>
								<div class="title" v-html="$t('contact.tel')"></div>
								<div class="title-content">{{ shopInfo.shopPhone }}</div>
							</div>
							<div class="padding-top: 10px;" v-if="contactInfo && contactInfo.length !=0">
								<div style="display: flex;padding-top: 10px;" v-for="(item, index) in contactInfo"
									:key="index">
									<div class="left-line"></div>
									<div class="title" v-html="$t('contact.contacts')"></div>
									<div class="title-content">
										{{ item.username +' ' + item.phone }}
									</div>
								</div>
							</div>
							<div style="display: flex;padding-top: 10px;" v-if="shopInfo.shopEmail">
								<div class="left-line"></div>
								<div class="title" v-html="$t('contact.email')"></div>
								<div class="title-content">{{ shopInfo.shopEmail }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div>
				<div class="call-me">{{$t('contact.callme')}}</div>
				<div class="call-me-tag">CONTACT US</div>
			</div>

			<a class="call" @click="show = true">
				<img src="@/assets/img/contact/dianhua.png" alt="" />
				<span style="color: white">{{$t('contact.call')}}</span>
			</a>
			<a class="navigation" @click="goMap">
				<img src="@/assets/img/contact/daohang.png" alt="" />
				<span style="color: #fff">{{$t('contact.toMap')}}</span>
			</a>
		</div>
		<!-- 水印 -->
		<WaterMark />
		<!-- :style="{ height: '40%' }" -->
		<van-popup v-model="show" position="bottom" style="padding-bottom: 90px;">
			<div class="tel-box">
				<div class="user-tel">
					<a v-for="(item, index) in telList" :key="index" :href="'tel:' + item.phone"
						:class="index == telList.length - 1?'':'border-bottom'">{{ item.username }} {{ item.phone }}</a>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import {
		Popup
	} from 'vant';
	import TopNavBar from './components/TopNavBar.vue';
	import Swipe from '@/components/common/Swipe.vue';
	
	import {
		getFileList
	} from '@/api';
	export default {
		name: 'Contact',
		components: {
			TopNavBar,
			Swipe,
			'van-popup': Popup
		},
		data() {
			return {
				totalShopAddr: localStorage.getItem('totalShopAddr'),
				telList: [],
				shopPhone: '', // 店铺电话
				show: false,
				typeName: 'dePersonalCenter',
				swipeList: '',
				backGroundImgs: ''
			};
		},
		computed: {
			mapHref() {
				const list = this.$store.state.shopInfo?.list || {};
				const eword = list.shopName;
				const latitudeC = list.latitudeC.split(',');
				const epointx = latitudeC[0];
				const epointy = latitudeC[1];
				// 腾讯地图key值
				const key = 'TZ3BZ-UHE3D-3M44O-PFNLP-W6EZT-VSBMO';
				return `https://apis.map.qq.com/tools/routeplan/eword=${eword}&epointx=${epointx}&epointy=${epointy}?referer=myapp&key=${key}&zoombutton=0&positionbutton=0`;
			},
			shopInfo() {
				const shopInfo_ = this.$store.state.shopInfo?.list || {};
				this.telList = [];
				if (shopInfo_.shopPhone != '' && shopInfo_.shopPhone != null) {
					this.telList.push({
						phone: shopInfo_.shopPhone,
						username: ''
					});
				}

				this.contactInfos();
				return this.$store.state.shopInfo?.list || {};
			},
			contactInfo() {
				const contacts = this.$store.state.shopInfo?.count;
				return contacts;
			},
			deLogoSquare() {
				const logo = this.$store.state.deLogoSquare;
				return logo;
			}
		},
		created() {
			this.getFileList();
		},
		methods: {
			// 获取列表数据
			getFileList() {
				const params = {
					page: 0,
					pageSize: 0,
					typeName: this.typeName,
					parentUuid: this.$store.state.shopUuid
				};
				getFileList(params).then(res => {
					if (res.state == 100 && res.items.length != 0) {
						this.swipeList = res.items[0].filePath;
						this.backGroundImgs = "url(" + this.swipeList + ")"
					}
				});
			},
			goMap() {
				const list = this.$store.state.shopInfo?.list || {};
				const {
					shopName,
					detailAddr,
					latitudeC
				} = list;

				const latitude = latitudeC.split(',');
				const epointx = latitude[0];
				const epointy = latitude[1];
				// wx.openLocation({
				// 	latitude: epointy, // 纬度，浮点数，范围为90 ~ -90
				// 	longitude: epointx, // 经度，浮点数，范围为180 ~ -180。
				// 	name: shopName, // 位置名
				// 	address: detailAddr, // 地址详情说明
				// 	scale: 12 // 地图缩放级别,整型值,范围从1~28。默认为最大
				// 	// infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
				// });
				const key = 'TZ3BZ-UHE3D-3M44O-PFNLP-W6EZT-VSBMO';
			    window.location.href= `https://apis.map.qq.com/tools/routeplan/eword=${shopName}&epointx=${epointx}&epointy=${epointy}?referer=myapp&key=${key}&zoombutton=0&positionbutton=0`;
				
			},
			contactInfos() {
				const contacts_ = this.$store.state.shopInfo?.count;
				if (contacts_) {
					contacts_.forEach((item, index) => {
						this.telList.push({
							phone: item.phone,
							username: item.username
						});
					});
				}

			}
		}
	};
</script>

<style lang="less" scoped>
	html,
	body {
		height: 100%;
		width: 100%;
		background-color: #f0f0f0;
	}

	.contact-container {
		background-color: #f0f0f0;
		padding-bottom: 100px;

		// padding-top: 60px;
		.contant-header {
			width: 100%;
			height: 250px;
		}

		.contant-header::after {
			content: '';
			width: 100%;
			position: absolute;
			background-image: url(../../assets/img/contact/c-bg-img.png);
			background-size: 100% 100%;
			height: 250px;
			z-index: 0;
			top: 0;
			left: 0;
		}

		.bg-img {
			width: 100%;
			height: 250px;
		}

		.default-img {
			width: 100%;
			height: 250px;
			background: #1989fa;
		}

		.text-title {
			position: absolute;
			top: 20px;
			width: 100%;
			text-align: center;
			color: #ffffff;
			font-size: 15px;
			font-weight: 400;
		}

		&::before {
			content: '';
			display: table;
		}

		.top-nav-bar {
			position: fixed;
			top: 0;
			width: 100%;
			height: 60px;
			z-index: 999;
		}

		.advertising-box {
			position: relative;
			height: 70px;
			top: 10px;

			.advertising {
				width: 343px;
				height: 109px;
				background: #ffffff;
				box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
				border-radius: 20px;
				position: absolute;
				left: calc(50% - 171.5px);
				top: -50px;
				display: flex;
				justify-content: space-around;
				align-items: center;

				.advertising-item {
					display: flex;
					flex-direction: column;
					align-items: center;

					.advertising-img {
						width: 38px;
						height: 38px;
					}

					.advertising-title {
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #969696;
						line-height: 24px;
					}
				}
			}
		}

		.footer {
			width: 100%;
			margin: 0 auto;
			position: absolute;
			// top: 194px;
			left: 0px;
			padding-bottom: 124px;
			background-color: #f0f0f0;

			.p-logo {
				display: flex;
				justify-content: center;
				position: absolute;
				top: -110px;
				width: 100%;
			}

			.logo {
				background-color: #ffffff;
				border-radius: 50%;
				padding: 6px;

				img {
					width: 70px;
					height: 70px;
				}
			}

			.flex-center {
				display: flex;
				justify-content: center;
			}

			.call-me {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #b4b4b4;
				line-height: 22px;
				text-align: center;
				padding-top: 32px;
				position: relative;
			}

			.call-me::before {
				width: 55px;
				top: 80%;
				content: '';
				height: 2px;
				left: calc(120px - 10vw);
				background: #dcdcdc;
				position: absolute;
			}

			.call-me::after {
				width: 55px;
				top: 80%;
				right: calc(120px - 10vw);
				content: '';
				height: 2px;
				background: #dcdcdc;
				position: absolute;
			}

			.call-me-tag {
				font-size: 9px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #c8c8c8;
				line-height: 22px;
				text-align: center;
			}

			.footer-contant {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				background: #ffffff;
				border-radius: 20px;
				padding: 23px 0px 23px 28px;
				padding-top: 68px;
				margin-top: -70px;
				width: 340px;

				.contant-img {
					width: 151px;
					margin-bottom: 14px;
				}

				.address {
					.line {
						display: flex;
						align-items: flex-start;
						font-size: 13px;

						.left {
							min-width: 50px;
							display: flex;
							justify-content: space-between;
							margin-right: 8px;
							font-size: 13px;
						}

						.right {
							word-break: break-all;
							font-size: 13px;
						}
					}
				}

				.contact-info {
					display: flex;
				}

				.contant-text {
					font-size: 13px;
					font-family: MicrosoftYaHei;
					font-weight: 500;
					color: #969696;

					.left-line {
						margin-top: 1px;
						width: 3px;
						height: 12px;
						background: #36C9FF;
						border-radius: 1px;
					}

					.title {
						width: 70px;
						padding-left: 8px;
						font-size: 13px !important;
						color: #A0A0A0;
						font-family: HarmonyOS Sans SC;
						font-weight: 400;
					}

					.title-content {
						font-size: 13px !important;
						color: #A0A0A0;
						font-family: HarmonyOS Sans SC;
						font-weight: 400;
					}
				}
			}

			.call,
			.navigation {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 226px;
				height: 50px;
				background: linear-gradient(90deg, #335EFF 0%, #2395FF 100%);
				box-shadow: 2px 2px 8px 0px rgba(80, 133, 241, 0.7);
				border-radius: 25px;
				font-size: 15px;
				margin: 26px auto 0;

				&>img {
					width: 26.5px;
					height: 26.5px;
					margin-right: 15.5px;
				}
			}

			.call {
				background: linear-gradient(-90deg, #27DEFF 0%, #27DEFF 100%);
				box-shadow: 2px 2px 7px 1px rgba(39, 222, 255, 0.5);
			}
		}

		.tel-box {
			width: 100%;
			min-height: 106px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.user-tel {
				width: 100%;
				min-height: 50px;
				background-color: white;
				display: flex;
				flex-direction: column;
			}

			.cancel-call {
				width: 100%;
				height: 50px;
				text-align: center;
				background-color: white;
				color: #323232;
				font-size: 16px;
				line-height: 50px;
				margin-top: 6px;
			}

			.border-bottom {
				border: 0.5px solid #f5f5f5;
			}

			;

			.user-tel>a {
				width: 100%;
				height: 50px;
				text-align: center;
				background-color: white;
				color: #323232;
				font-size: 16px;
				line-height: 50px;
			}
		}
	}
</style>
